@font-face {
  font-family: PlusJakartaSans;
  font-weight: 600;
  font-display: swap;
  src: url("PlusJakartaSans-SemiBold.02bff084.ttf");
}

@font-face {
  font-family: PlusJakartaSans;
  font-weight: 700;
  font-display: swap;
  src: url("PlusJakartaSans-Bold.57595c4e.ttf");
}

@font-face {
  font-family: DMSans;
  font-weight: 500;
  font-display: swap;
  src: url("DMSans-Medium.0957dae6.ttf");
}

@font-face {
  font-family: DMSans;
  font-weight: 700;
  font-display: swap;
  src: url("DMSans-Bold.51beb29d.ttf");
}

h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 500;
  line-height: 62px;
}

@media only screen and (width >= 1020px) {
  h1 {
    font-size: 72px;
    line-height: 78px;
  }
}

h1 + h5, ol.main-list > h1 + li {
  margin-top: 20px;
}

h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 500;
}

h2 + h5, ol.main-list > h2 + li {
  margin-top: 30px;
}

h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
}

h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

h5, ol.main-list > li {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

h5 + p, ol.main-list > li + p {
  margin-top: 20px;
}

h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 2.4em;
}

h6 + p {
  margin-top: 12px;
}

p, ol, ul {
  line-height: 30px;
}

p + p, ol + p, ul + p {
  margin-top: 14px;
}

p + h5, ol.main-list > p + li, p + h6, ol + h5, ol.main-list > ol + li, ol + h6, ul + h5, ol.main-list > ul + li, ul + h6 {
  margin-top: 30px;
}

.button {
  width: fit-content;
  min-width: 155px;
  height: 48px;
}

.button.primary-button {
  appearance: none;
  text-align: center;
  color: #004e34;
  background: #00de95;
  border: 2px solid #00de95;
  border-radius: 15px;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: .4rem 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button.primary-button:hover {
  background: #a6f4da;
  border-color: #a6f4da;
}

.button.primary-button:disabled {
  color: #739e90;
}

.button.secondary-button {
  appearance: none;
  text-align: center;
  color: #004e34;
  background: #a6f4da;
  border: 2px solid #004e34;
  border-radius: 15px;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: .4rem 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button.secondary-button:hover {
  border-color: #40e6b0;
}

.button.secondary-button:disabled {
  color: #739e90;
}

.button.tersery-button {
  appearance: none;
  text-align: center;
  color: #004e34;
  background: #fff0;
  border: 2px solid #a6f4da;
  border-radius: 15px;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: .4rem 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button.tersery-button:hover {
  border-color: #40e6b0;
}

.button.tersery-button:disabled {
  color: #739e90;
}

.button + .button {
  margin-left: .8rem;
}

header ul, footer ul {
  margin: 0;
  padding: 0;
}

header ul li, footer ul li {
  line-height: 2.4em;
  display: block;
}

header {
  color: #004e34;
  height: inherit;
  background-color: #aaedd6;
  border-radius: 20px 20px 0 0;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
  padding: 20px;
  display: flex;
}

@media only screen and (width >= 768px) {
  header {
    background-color: #d1f5e5;
  }
}

header.curved {
  border-radius: 20px;
}

header .burger-button {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 1.7rem;
  right: 2rem;
}

header .burger-button .burger-icon {
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
}

header .burger-button .burger-icon, header .burger-button .burger-icon:before, header .burger-button .burger-icon:after {
  background-color: #004e34;
  outline: 1px solid #0000;
  width: 32px;
  height: 3px;
  transition-property: background-color, transform;
  transition-duration: .3s;
  display: block;
}

header .burger-button .burger-icon:before, header .burger-button .burger-icon:after {
  content: "";
  position: absolute;
}

header .burger-button .burger-icon:before {
  top: -8px;
}

header .burger-button .burger-icon:after {
  top: 8px;
}

header .burger-button .burger-icon.active {
  background-color: #0000;
}

header .burger-button .burger-icon.active:before {
  transform: translateY(8px)rotate(45deg);
}

header .burger-button .burger-icon.active:after {
  transform: translateY(-8px)rotate(-45deg);
}

@media only screen and (width >= 1020px) {
  header .burger-button {
    display: none;
  }
}

header .logo {
  max-width: 250px;
}

header .logo img {
  width: 100%;
}

footer {
  background-color: #00a670;
  flex-flow: wrap;
  align-content: stretch;
  gap: 10px 45px;
  padding: 45px 40px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  footer {
    padding: 60px 100px;
  }
}

footer > * {
  flex: 100%;
}

footer > * + * {
  margin-top: 20px;
}

@media only screen and (width >= 1020px) {
  footer > * + * {
    margin-top: 0;
  }
}

footer, footer a {
  color: #fff;
}

footer .register, footer .copy {
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
}

footer .logo {
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
  display: flex;
}

footer .logo img {
  max-width: 230px;
}

footer .logo .daemon-logo {
  width: 127px;
}

@media only screen and (width >= 1020px) {
  footer > * {
    flex: 16%;
  }

  footer .logo, footer .address {
    flex: 25%;
  }

  footer .register {
    text-align: left;
    flex: 65%;
  }

  footer .copy {
    text-align: right;
    flex: 30%;
  }
}

footer ul li img {
  width: 22px;
  margin-right: 12px;
  position: relative;
  top: -2px;
}

footer .logo .logo-main-text {
  font-size: 32px;
}

footer .logo .logo-by-text {
  font-size: 12px;
  display: block;
}

.tiles {
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  margin-top: 50px;
  display: flex;
}

.tiles .tile-box {
  background-color: #a6f4da;
  border-radius: 20px;
  width: 140px;
  height: 140px;
}

.tiles .tile {
  flex: 0 315px;
  gap: 15px;
}

@media only screen and (width >= 1020px) {
  .tiles .tile {
    flex: 0 340px;
  }
}

.tiles .tile.tile-centre {
  text-align: center;
}

.tiles .tile.tile-centre .icon {
  margin: 0 auto;
}

.tiles .tile .icon + h3 {
  margin-top: 40px;
}

.tiles .tile .icon + h4 {
  margin-top: 20px;
}

.tiles .tile p {
  margin: 10px 0;
}

.box {
  border-radius: 20px;
  overflow: hidden;
}

.box.box-small-corners {
  border-radius: 10px;
}

.box .box-header {
  color: #004e34;
  text-align: center;
  padding: 17px;
  font-weight: 700;
}

.box .box-header img {
  margin-right: 10px;
}

.box .box-content {
  background: #fff;
  flex-flow: column;
  align-items: center;
  height: 100%;
  padding: 36px;
  display: flex;
}

.box.box-ice-with-border {
  background: #f2fdf9;
  border: 1px solid #00de95;
}

.box.box-white {
  background: #fff;
}

.area {
  border-radius: 20px;
  padding: 15px;
}

@media only screen and (width >= 768px) {
  .area {
    padding: 40px;
  }
}

.area.area-light, .area.area-dark {
  padding: 25px;
}

@media only screen and (width >= 768px) {
  .area.area-light, .area.area-dark {
    padding: 40px;
  }
}

.area.area-light {
  color: #004e34;
  background-color: #f2fdf9;
}

.area.area-dark {
  color: #fff;
  background-color: #00a670;
}

.area.area-dark h5 {
  color: #739e90;
}

.icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon.icon-round {
  background-color: #004e34;
  border: 7px solid #00a670;
  border-radius: 50%;
  width: 78px;
  height: 78px;
  box-shadow: 0 0 0 7px #00de95;
}

@media only screen and (width >= 768px) {
  .icon.icon-round {
    border-width: 10px;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 0 10px #00de95;
  }
}

.icon.icon-round img {
  width: 25px;
  height: 25px;
}

@media only screen and (width >= 768px) {
  .icon.icon-round img {
    width: 40px;
    height: 40px;
  }
}

.icon.icon-round .icon-round-inner {
  border: 3px solid #0000;
  border-radius: 50%;
}

.icon.icon-square {
  background-color: #fff;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  box-shadow: 0 0 7px 2px #004f3473;
}

@media only screen and (width >= 1020px) {
  .icon.icon-square {
    border-radius: 12px;
    width: 60px;
    height: 60px;
  }
}

.icon.icon-square img {
  object-fit: contain;
  width: 25px;
  height: 25px;
}

@media only screen and (width >= 1020px) {
  .icon.icon-square img {
    width: 40px;
    height: 40px;
  }
}

.icon.icon-square.icon-square-small {
  width: 32px;
  height: 32px;
}

@media only screen and (width >= 1020px) {
  .icon.icon-square.icon-square-small {
    width: 45px;
    height: 45px;
  }
}

.icon.icon-square.icon-square-small img {
  width: 16px;
  height: 16px;
}

@media only screen and (width >= 1020px) {
  .icon.icon-square.icon-square-small img {
    width: 20px;
    height: 20px;
  }
}

.icon.icon-shadow {
  backdrop-filter: blur(9.425px);
  background-color: #33b08714;
  background-clip: border-box;
  border-radius: 22px;
  padding: 10px;
}

.image-container {
  position: relative;
}

.image-container > * {
  position: absolute;
}

.slider {
  flex-flow: wrap;
  display: flex;
}

.slider .slider-title {
  flex: 1;
}

.slider .slider-buttons {
  flex: auto;
  justify-content: flex-end;
  display: none;
}

@media only screen and (width >= 1020px) {
  .slider .slider-buttons {
    display: flex;
  }
}

.slider .slider-buttons .slider-button {
  appearance: none;
  text-align: center;
  color: #004e34;
  background: #fff0;
  border: 2px solid #004e34;
  border-radius: 15px;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin: .4rem 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: relative;
  left: 0;
  right: 0;
}

.slider .slider-buttons .slider-button + .slider-button {
  margin-left: 15px;
}

.slider .slider-buttons .slider-button:hover {
  border-color: #40e6b0;
}

.slider .slider-slides {
  flex: 100%;
  margin-top: 34px;
}

.slider .slider-slides .slider-slide:not(.is-visible) {
  filter: opacity(.5);
}

.input-box {
  filter: drop-shadow(0 0 8px #6c6c6c70);
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 22px;
  flex-flow: wrap;
  align-items: center;
  max-width: 465px;
  padding: 1px 6px;
  display: flex;
  position: relative;
}

@media only screen and (width >= 768px) {
  .input-box {
    width: 100%;
  }
}

.input-box:hover {
  border-color: #00de95;
}

.input-box input[type="email"] {
  appearance: none;
  background: none;
  border: 0;
  flex: 190px;
  width: 100%;
  height: 46px;
  margin-top: 10px;
  padding: 6px 20px 0;
  transition: all .5s ease-in, all ease-out;
}

@media only screen and (width >= 768px) {
  .input-box input[type="email"] {
    height: 42px;
    margin-top: 0;
    padding: 0 20px;
  }
}

.input-box input[type="email"]:focus, .input-box input[type="email"]:active, .input-box input[type="email"]:not(:placeholder-shown):not(:focus) {
  background-color: #0000;
  outline: none;
  padding-top: 6px;
  padding-bottom: 0;
}

@media only screen and (width >= 768px) {
  .input-box input[type="email"]:focus, .input-box input[type="email"]:active, .input-box input[type="email"]:not(:placeholder-shown):not(:focus) {
    padding-top: 12px;
  }
}

.input-box input[type="email"]:focus::placeholder, .input-box input[type="email"]:active::placeholder, .input-box input[type="email"]:not(:placeholder-shown):not(:focus)::placeholder {
  opacity: 0;
}

.input-box input[type="email"]:focus + label, .input-box input[type="email"]:active + label, .input-box input[type="email"]:not(:placeholder-shown):not(:focus) + label {
  opacity: 1;
  font-size: 14px;
  top: 12px;
}

.input-box input[type="email"]::placeholder {
  color: #739e90;
  line-height: 15px;
}

.input-box label {
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  color: #739e90;
  font-size: 16px;
  line-height: 15px;
  transition: font .5s, top .5s;
  position: absolute;
  top: 24px;
  left: 26px;
  overflow: hidden;
}

.input-box .button {
  flex: 120px;
  margin: 8px 3px;
}

ol, ul {
  padding: 0;
}

ol + ol, ol ul, ul + ol, ul ul {
  margin-top: 45px;
}

ol li + li, ul li + li, ol li > ol, ol li ul, ul li > ol, ul li ul {
  margin-top: 10px;
}

ol {
  counter-reset: item;
}

ol > li {
  display: block;
}

ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

ol.main-list > li > p {
  font-size: 16px;
}

ol.main-list > li + li {
  margin-top: 22px;
}

ol.main-list > li > ul li, ol.main-list > li ol li {
  font-size: 16px;
}

ol.main-list > li > ul li + li, ol.main-list > li ol li + li {
  margin-top: 16px;
}

ol.main-list > li > ul li {
  margin-left: 15px;
}

table {
  font-size: 16px;
}

table thead {
  color: #fff;
  background-color: #00a670;
}

table thead tr th {
  padding: 8px;
}

table tbody tr:nth-child(odd) td {
  background-color: #f2fdf9;
}

table tbody td {
  padding: 8px;
}

table tbody td + td {
  border-left: 1px solid #004e34;
}

@media only screen and (width <= 768px) {
  table, table thead, table tbody, table th, table td, table tr {
    display: block;
  }

  table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  table tbody tr {
    border-top: 1px solid #004e34;
    border-bottom: 1px solid #004e34;
  }

  table tbody tr + tr {
    margin-top: 10px;
  }

  table tbody td {
    white-space: normal;
    text-align: left;
    border: none;
    min-height: 130px;
    padding-left: 50%;
    position: relative;
  }

  table tbody td + td {
    border: none;
    border-top: 1px solid #739e90;
  }

  table tbody td:before {
    text-align: left;
    color: #fff;
    content: attr(data-title);
    background-color: #00a670;
    width: calc(35% + 5vw);
    padding: 8px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.app-links {
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
}

a {
  color: #004e34;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.mail-to, a.hero-link {
  font-weight: 700;
  text-decoration: underline;
}

a.mail-to:hover, a.hero-link:hover {
  text-decoration: none;
}

.articles {
  flex-flow: wrap;
  justify-content: center;
  gap: 15px;
  display: flex;
}

.articles .article {
  flex: 100%;
}

@media only screen and (width >= 1020px) {
  .articles .article {
    flex: 0 312px;
  }
}

.article {
  background: #fff;
  border-radius: 24px;
  padding: 6px;
}

.article img {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  max-height: 400px;
}

.article h6 {
  color: #739e90;
}

.article .article-text {
  white-space: normal;
  padding: 10px;
  font-size: 25px;
}

.hero {
  background-image: url("header_background_mobile.4a17d3c6.png");
  background-position: 50% -80px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 20px 20px;
  flex-flow: wrap;
  justify-content: center;
  gap: 35px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width >= 768px) {
  .hero {
    background-image: url("header_background_desktop.eb582dcc.png"), url("header_background_desktop_2.add060fd.png");
    background-position: 50% -80px, 50% -80px;
    background-repeat: no-repeat, no-repeat;
    background-size: auto;
  }
}

.hero.hero-landing .hero-text {
  flex: 0 600px;
}

@media only screen and (width >= 768px) {
  .hero.hero-landing {
    min-height: 585px;
  }
}

.hero h1 {
  font-size: 56px;
  line-height: 65px;
}

@media only screen and (width >= 1020px) {
  .hero h1 {
    font-size: 80px;
    line-height: 95px;
  }
}

.hero .hero-text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .hero .hero-text {
    margin: 40px 0 40px 45px;
  }
}

.hero .hero-text .button {
  padding: 0 32px;
  font-size: 20px;
  line-height: 3.2rem;
}

.hero .hero-graphics {
  flex: 0 600px;
  position: relative;
}

.hero .hero-graphics .hero-app-links {
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 15px;
  width: 100%;
  display: flex;
}

@media only screen and (width >= 768px) {
  .hero .hero-graphics .hero-app-links {
    width: 150px;
  }
}

.hero .hero-graphics .hero-app-links .header {
  flex: 100%;
}

.hero .hero-graphics .hero-app-links .button {
  margin: 0;
}

.hero .hero-graphics .hero-greg-cutout {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
  display: none;
}

@media only screen and (width >= 768px) {
  .hero .hero-graphics {
    height: 555px;
  }

  .hero .hero-graphics .hero-app-links, .hero .hero-graphics .hero-greg-cutout, .hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
    position: absolute;
  }

  .hero .hero-graphics .hero-app-links {
    z-index: 2;
    top: 0;
    left: 0;
  }

  .hero .hero-graphics .hero-greg-cutout {
    z-index: 1;
    width: 520px;
    margin: 0;
    top: 100px;
    left: 10px;
  }

  .hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
    display: initial;
  }

  .hero .hero-graphics .hero-ipad {
    z-index: 0;
    width: 325px;
    top: 55px;
    left: 229px;
  }

  .hero .hero-graphics .hero-mobile {
    z-index: 1;
    width: 140px;
    top: 227px;
    left: 480px;
  }
}

.blogs.area {
  padding: 60px 20px 20px;
}

.blogs .blog-header {
  margin-bottom: 40px;
}

.blogs .articles {
  justify-content: flex-start;
}

@media only screen and (width >= 1020px) {
  .blogs .articles img {
    height: 220px;
  }
}

.hero-blog {
  justify-content: space-between;
}

.hero-blog .article {
  flex: 100%;
}

.hero-blog .hero-text {
  flex: 400px;
}

.hero-blog .hero-text .blog-author {
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.hero-blog .hero-text .blog-author img {
  border: 6px solid #00de95;
  border-radius: 50%;
  margin-right: 15px;
  padding: 6px;
}

.hero-blog .blog-image {
  flex: 510px;
  margin: 20px -15px -15px;
  overflow: hidden;
}

@media only screen and (width >= 1020px) {
  .hero-blog .blog-image {
    border-top-left-radius: 20px;
    margin: -15px;
  }
}

.hero-blog .blog-image img {
  object-fit: cover;
  width: 100%;
  height: 320px;
}

.blog-text {
  margin: 0 auto;
  padding: 15px;
}

@media only screen and (width >= 1020px) {
  .blog-text {
    width: 100%;
    max-width: 760px;
    padding: 40px;
  }

  .blog-text p {
    font-size: 20px;
  }
}

.blog-text img {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  max-height: 300px;
}

.blog-share {
  margin: 20px 0 60px;
}

.blog-share ul {
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .blog-share ul {
    gap: 45px;
  }
}

.blog-share ul li {
  margin: 0;
  padding: 0;
  display: block;
}

.blog-share ul li a {
  background-color: #00a670;
  border: 4px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  display: flex;
  box-shadow: 0 0 0 6px #00de95;
}

.blog-share ul li a img {
  width: 24px;
  height: 24px;
}

@media only screen and (width >= 1020px) {
  .blog-share ul li a {
    width: 62px;
    height: 62px;
  }

  .blog-share ul li a img {
    width: 32px;
    height: 32px;
  }
}

body {
  color: #004e34;
  font: 500 16px DMSans, sans-serif;
}

main, footer {
  visibility: visible;
  opacity: 1;
  transition: visibility .3s, opacity .3s linear;
}

main.mobile-hidden, footer.mobile-hidden {
  visibility: hidden;
  opacity: 0;
}

@media only screen and (width >= 1020px) {
  main.mobile-hidden, footer.mobile-hidden {
    visibility: visible;
    opacity: 1;
  }
}

.container {
  max-width: 1420px;
  margin: 0 auto;
  padding: 5px;
}

@media only screen and (width >= 1020px) {
  .container {
    padding: 10px;
  }
}

.section + .section {
  margin-top: 15px;
}

.navigation {
  width: 100%;
}

nav {
  flex: fit-content;
  z-index: 999;
  color: #004e34;
  background-color: #aaedd6;
  border-radius: 0 0 20px 20px;
  flex-flow: column;
  height: calc(100vh - 9rem);
  margin: 0 5px 5px;
  padding: 1rem;
  transition: transform .3s ease-in;
  display: flex;
  position: fixed;
  inset: calc(73px + 3vw) 0 0;
  transform: translate(calc(100% + 5px));
}

nav.active {
  transform: none;
}

@media only screen and (width >= 1020px) {
  nav {
    background-color: #0000;
    flex-flow: row;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    transition: none;
    position: relative;
    top: 0;
    transform: none;
  }
}

nav ul {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  height: auto;
  padding: 0;
  display: flex;
  position: relative;
  top: 0;
  transform: none;
}

@media only screen and (width >= 1020px) {
  nav ul {
    flex-flow: row;
    justify-content: center;
  }
}

nav ul li {
  line-height: 4em;
  display: inline-block;
}

@media only screen and (width >= 1020px) {
  nav ul li {
    margin: 0 15px;
  }
}

nav a {
  font-size: 32px;
  font-weight: 700;
}

@media only screen and (width >= 1020px) {
  nav a {
    font-size: 16px;
  }
}

nav .button + .button {
  margin-left: 0;
}

@media only screen and (width >= 1020px) {
  nav .button + .button {
    margin-left: 20px;
  }
}

nav .button.primary-button, nav .button.secondary-button {
  width: auto;
}

.hero.hero-404 .hero-text {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
}

.hero.hero-404 .hero-text h2, .hero.hero-404 .hero-text h3, .hero.hero-404 .hero-text h5 {
  text-align: center;
  flex: 100%;
}

.hero.hero-404 .hero-text h3 {
  color: #a6c1b8;
}

.hero.hero-404 .hero-text h2 {
  margin-top: 10px;
}

.hero.hero-404 .hero-text h5 {
  color: #739e90;
  margin-top: 10px;
  margin-bottom: 40px;
}

.featured {
  white-space: nowrap;
  justify-content: center;
  gap: 15px;
  display: flex;
  overflow-x: hidden;
}

.featured img {
  object-fit: cover;
  border-radius: 20px;
  width: 300px;
  height: 300px;
}

@media only screen and (width >= 768px) {
  .featured img {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (width >= 1020px) {
  .featured img {
    width: 465px;
    height: 465px;
  }
}

.points.tiles {
  gap: 60px;
  padding: 100px 50px;
}

.planner {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 80px;
  padding: 80px 30px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .planner {
    background-image: url("panoramic_background.2fec6044.png");
    background-position: top;
    background-repeat: no-repeat;
    flex-direction: row;
    gap: 15px;
    padding: 120px 50px;
  }
}

.planner .planner-images {
  order: 2;
  justify-content: center;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1020px) {
  .planner .planner-images {
    flex: 0 250px;
    order: 1;
  }
}

.planner .planner-images .image {
  border-radius: 15px;
  width: 280px;
}

@media only screen and (width >= 768px) {
  .planner .planner-images .image {
    width: 353px;
  }
}

.planner .planner-texts {
  flex: 2 500px;
  order: 1;
}

@media only screen and (width >= 1020px) {
  .planner .planner-texts {
    flex: 0 500px;
    order: 2;
  }
}

.planner .planner-texts .button {
  margin-top: 30px;
}

.recipes.area {
  padding: 30px 20px;
}

@media only screen and (width >= 1020px) {
  .recipes.area {
    padding: 60px 40px;
  }
}

.recipes .recipe img {
  object-fit: cover;
  border-radius: 20px;
  width: -webkit-fill-available;
  height: 220px;
  padding: 0;
  display: block;
}

@media only screen and (width >= 768px) {
  .recipes .recipe img {
    height: 280px;
    margin: 0;
  }
}

@media only screen and (width >= 1020px) {
  .recipes .recipe img {
    height: 320px;
    margin: 0;
  }
}

.recipes .recipe .recipe-text {
  max-width: 320px;
  margin: 10px auto 0;
}

.additional .button-container {
  flex: 100%;
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.testimonials {
  padding: 20px;
}

@media only screen and (width >= 1020px) {
  .testimonials {
    padding: 85px 180px;
  }
}

.testimonials h4 {
  text-align: center;
}

@media only screen and (width >= 1020px) {
  .testimonials h4 {
    text-align: left;
  }
}

.testimonials .testimonial {
  flex-flow: column;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .testimonials .testimonial {
    flex-flow: row;
  }
}

.testimonials .testimonial .testimonial-person {
  flex-direction: column;
  flex: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .testimonials .testimonial .testimonial-person {
    margin-right: 60px;
  }
}

.testimonials .testimonial .testimonial-person .image-border {
  border: 6px solid #00de95;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin-bottom: 10px;
  padding: 6px;
  display: flex;
}

.testimonials .testimonial .testimonial-person .image-border img {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
}

.testimonials .testimonial .testimonial-text {
  flex: 1;
  margin-top: 40px;
}

@media only screen and (width >= 1020px) {
  .testimonials .testimonial .testimonial-text {
    align-items: center;
    margin-top: 0;
    display: flex;
  }
}

.testimonials .testimonial .testimonial-text h5:before {
  content: open-quote;
}

.testimonials .testimonial .testimonial-text h5:after {
  content: close-quote;
}

.signup {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 80px 15px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width >= 1020px) {
  .signup {
    flex-flow: row;
    height: 435px;
  }
}

.signup .signup-images {
  flex-flow: wrap;
  place-content: space-around center;
  gap: 15px;
  min-width: 315px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images {
    column-count: 2;
    flex-direction: column;
    justify-content: flex-start;
    height: 565px;
    margin-top: -34px;
  }
}

.signup .signup-images.images-left {
  margin-left: -70px;
  margin-right: -70px;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images.images-left {
    margin-left: -70px;
    margin-right: 0;
  }
}

.signup .signup-images.images-right {
  display: none;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images.images-right {
    margin-right: -70px;
    display: flex;
  }
}

.signup .signup-images img {
  object-fit: cover;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: block;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images img {
    width: 150px;
    height: 150px;
  }
}

.signup .signup-images .spacer-column {
  height: 0;
  display: none;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images .spacer-column {
    height: 65px;
    display: block;
  }
}

.signup .signup-images .spacer-row {
  width: 65px;
}

@media only screen and (width >= 1020px) {
  .signup .signup-images .spacer-row {
    width: 0;
    display: none;
  }
}

.signup h3, .signup p {
  text-align: center;
}

.signup .signup-content {
  flex-flow: column;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .signup .signup-content {
    flex: 0 654px;
    margin-bottom: 0;
  }
}

.download {
  flex-flow: column;
  gap: 15px;
  padding: 40px;
  display: flex;
  overflow: hidden;
}

.download.area {
  color: #fff;
  background-color: #004e34;
}

@media only screen and (width >= 1020px) {
  .download {
    flex-flow: row;
    padding: 100px;
  }
}

.download .download-content {
  flex: 1;
}

.download .download-content ul {
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;
}

.download .download-content ul li {
  padding-left: 50px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
}

.download .download-content ul li + li {
  margin-top: 20px;
}

.download .download-content ul li:before {
  content: url("tick_mark.0042c4bf.svg");
  background-color: #40e6b0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  left: 0;
}

.download .download-pricing {
  flex-flow: column;
  flex: 1;
  align-items: center;
  margin-top: 75px;
  display: flex;
}

@media only screen and (width >= 1020px) {
  .download .download-pricing {
    margin-top: 0;
  }
}

.download .download-pricing .boxes {
  flex-flow: wrap;
  gap: 15px;
  display: flex;
  position: relative;
}

.download .download-pricing .boxes .box {
  z-index: 1;
  flex: 220px;
  margin-bottom: 20px;
}

.download .download-pricing .boxes .price {
  color: #004e34;
  font-size: 40px;
  font-weight: 500;
}

.download .download-pricing .boxes .per {
  color: #739e90;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.download .download-pricing .boxes .other {
  color: #407a67;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.download .download-pricing .boxes .button {
  margin-top: 40px;
}

.download .download-pricing .app-links {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

.download .download-pricing .app-links h5 {
  text-align: center;
  flex: 100%;
}

.press {
  padding: 15px;
}

@media only screen and (width >= 1020px) {
  .press {
    padding: 70px;
  }
}

.press .press-content {
  flex-flow: wrap;
  align-items: center;
  gap: 45px;
  margin-top: 15px;
  display: flex;
}

.press .press-content .press-image {
  margin: 0 auto;
}

.press .press-content .press-image img {
  border-radius: 20px;
  width: 100%;
}

@media only screen and (width >= 1020px) {
  .press .press-content .press-image img {
    width: auto;
  }
}

.press .press-content .press-text {
  color: #739e90;
  flex: 520px;
  font-size: 32px;
}

.press .press-content .press-newspapers {
  flex-flow: wrap;
  flex: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 45px;
  display: flex;
}

.press .press-content .press-newspapers img {
  object-fit: contain;
  width: 100%;
  max-height: 72px;
}

@media only screen and (width >= 1020px) {
  .press .press-content .press-newspapers img {
    width: auto;
    height: 100px;
  }
}
/*# sourceMappingURL=index.56885fdf.css.map */
