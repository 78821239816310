@font-face {
  font-family: PlusJakartaSans;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/PlusJakartaSans-SemiBold.ttf);
}
@font-face {
  font-family: PlusJakartaSans;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/PlusJakartaSans-Bold.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/DMSans-Bold.ttf);
}
h1 {
  margin: 0;
  font-weight: 500;
  font-size: 56px;
  line-height: 62px;
}
@media only screen and (min-width: 1020px) {
  h1 {
    font-size: 72px;
    line-height: 78px;
  }
}
h1 + h5, ol.main-list > h1 + li {
  margin-top: 20px;
}

h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 500;
}
h2 + h5, ol.main-list > h2 + li {
  margin-top: 30px;
}

h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
}

h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

h5, ol.main-list > li {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
h5 + p, ol.main-list > li + p {
  margin-top: 20px;
}

h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 2.4em;
}
h6 + p {
  margin-top: 12px;
}

p, ol, ul {
  line-height: 30px;
}
p + p, ol + p, ul + p {
  margin-top: 14px;
}
p + h5, ol.main-list > p + li, p + h6, ol + h5, ol.main-list > ol + li, ol + h6, ul + h5, ol.main-list > ul + li, ul + h6 {
  margin-top: 30px;
}

.button {
  height: 48px;
  width: fit-content;
  min-width: 155px;
}
.button.primary-button {
  appearance: none;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #00DE95;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  background: #00DE95;
  color: #004E34;
}
.button.primary-button:hover {
  border-color: #A6F4DA;
  background: #A6F4DA;
}
.button.primary-button:disabled {
  color: #739E90;
}
.button.secondary-button {
  appearance: none;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #004E34;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  background: #A6F4DA;
  color: #004E34;
}
.button.secondary-button:hover {
  border-color: #40E6B0;
}
.button.secondary-button:disabled {
  color: #739E90;
}
.button.tersery-button {
  appearance: none;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #A6F4DA;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  color: #004E34;
}
.button.tersery-button:hover {
  border-color: #40E6B0;
}
.button.tersery-button:disabled {
  color: #739E90;
}
.button + .button {
  margin-left: 0.8rem;
}

header ul, footer ul {
  margin: 0;
  padding: 0;
}
header ul li, footer ul li {
  display: block;
  line-height: 2.4em;
}

header {
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background-color: #aaedd6;
  color: #004E34;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 0;
  height: inherit;
}
@media only screen and (min-width: 768px) {
  header {
    background-color: #d1f5e5;
  }
}
header.curved {
  border-radius: 20px;
}
header .burger-button {
  background: 0 0;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 1.7rem;
}
header .burger-button .burger-icon {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}
header .burger-button .burger-icon, header .burger-button .burger-icon:before, header .burger-button .burger-icon:after {
  display: block;
  width: 32px;
  height: 3px;
  outline: 1px solid transparent;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
  background-color: #004E34;
}
header .burger-button .burger-icon:before, header .burger-button .burger-icon:after {
  position: absolute;
  content: "";
}
header .burger-button .burger-icon::before {
  top: -8px;
}
header .burger-button .burger-icon::after {
  top: 8px;
}
header .burger-button .burger-icon.active {
  background-color: transparent;
}
header .burger-button .burger-icon.active::before {
  transform: translateY(8px) rotate(45deg);
}
header .burger-button .burger-icon.active::after {
  transform: translateY(-8px) rotate(-45deg);
}
@media only screen and (min-width: 1020px) {
  header .burger-button {
    display: none;
  }
}
header .logo {
  max-width: 250px;
}
header .logo img {
  width: 100%;
}

footer {
  padding: 45px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 10px 45px;
  background-color: #00A670;
}
@media only screen and (min-width: 1020px) {
  footer {
    padding: 60px 100px;
  }
}
footer > * {
  flex: 100%;
}
footer > * + * {
  margin-top: 20px;
}
@media only screen and (min-width: 1020px) {
  footer > * + * {
    margin-top: 0;
  }
}
footer, footer a {
  color: #fff;
}
footer .register, footer .copy {
  margin-top: 50px;
  font-size: 14px;
  text-align: center;
}
footer .logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
}
footer .logo img {
  max-width: 230px;
}
footer .logo .daemon-logo {
  width: 127px;
}
@media only screen and (min-width: 1020px) {
  footer > * {
    flex: 16%;
  }
  footer .logo, footer .address {
    flex: 25%;
  }
  footer .register {
    flex: 65%;
    text-align: left;
  }
  footer .copy {
    flex: 30%;
    text-align: right;
  }
}
footer ul li img {
  position: relative;
  top: -2px;
  margin-right: 12px;
  width: 22px;
}
footer .logo .logo-main-text {
  font-size: 32px;
}
footer .logo .logo-by-text {
  display: block;
  font-size: 12px;
}

.tiles {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
}
.tiles .tile-box {
  height: 140px;
  width: 140px;
  border-radius: 20px;
  background-color: #A6F4DA;
}
.tiles .tile {
  flex: 0 1 315px;
  gap: 15px;
}
@media only screen and (min-width: 1020px) {
  .tiles .tile {
    flex: 0 1 340px;
  }
}
.tiles .tile.tile-centre {
  text-align: center;
}
.tiles .tile.tile-centre .icon {
  margin: 0 auto;
}
.tiles .tile .icon + h3 {
  margin-top: 40px;
}
.tiles .tile .icon + h4 {
  margin-top: 20px;
}
.tiles .tile p {
  margin: 10px 0;
}

.box {
  border-radius: 20px;
  overflow: hidden;
}
.box.box-small-corners {
  border-radius: 10px;
}
.box .box-header {
  padding: 17px;
  color: #004E34;
  text-align: center;
  font-weight: 700;
}
.box .box-header img {
  margin-right: 10px;
}
.box .box-content {
  height: 100%;
  background: #fff;
  padding: 36px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.box.box-ice-with-border {
  border-style: solid;
  border-width: 1px;
  border-color: #00DE95;
  background: #F2FDF9;
}
.box.box-white {
  background: #fff;
}

.area {
  padding: 15px;
  border-radius: 20px;
}
@media only screen and (min-width: 768px) {
  .area {
    padding: 40px;
  }
}
.area.area-light, .area.area-dark {
  padding: 25px;
}
@media only screen and (min-width: 768px) {
  .area.area-light, .area.area-dark {
    padding: 40px;
  }
}
.area.area-light {
  background-color: #F2FDF9;
  color: #004E34;
}
.area.area-dark {
  background-color: #00A670;
  color: #fff;
}
.area.area-dark h5 {
  color: #739E90;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon.icon-round {
  height: 78px;
  width: 78px;
  border-style: solid;
  border-width: 7px;
  border-radius: 50%;
  border-color: #00A670;
  box-shadow: 0 0 0 7px #00DE95;
  background-color: #004E34;
}
@media only screen and (min-width: 768px) {
  .icon.icon-round {
    height: 100px;
    width: 100px;
    border-width: 10px;
    box-shadow: 0 0 0 10px #00DE95;
  }
}
.icon.icon-round img {
  height: 25px;
  width: 25px;
}
@media only screen and (min-width: 768px) {
  .icon.icon-round img {
    height: 40px;
    width: 40px;
  }
}
.icon.icon-round .icon-round-inner {
  border-color: transparent;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
}
.icon.icon-square {
  background-color: #fff;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  box-shadow: 0 0 7px 2px rgba(0, 79, 52, 0.4509803922);
}
@media only screen and (min-width: 1020px) {
  .icon.icon-square {
    border-radius: 12px;
    height: 60px;
    width: 60px;
  }
}
.icon.icon-square img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
@media only screen and (min-width: 1020px) {
  .icon.icon-square img {
    height: 40px;
    width: 40px;
  }
}
.icon.icon-square.icon-square-small {
  height: 32px;
  width: 32px;
}
@media only screen and (min-width: 1020px) {
  .icon.icon-square.icon-square-small {
    height: 45px;
    width: 45px;
  }
}
.icon.icon-square.icon-square-small img {
  height: 16px;
  width: 16px;
}
@media only screen and (min-width: 1020px) {
  .icon.icon-square.icon-square-small img {
    height: 20px;
    width: 20px;
  }
}
.icon.icon-shadow {
  padding: 10px;
  background-color: rgba(51, 176, 135, 0.08);
  backdrop-filter: blur(9.425px);
  border-radius: 22px;
  background-clip: border-box;
}

.image-container {
  position: relative;
}
.image-container > * {
  position: absolute;
}

.slider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.slider .slider-title {
  flex: 1;
}
.slider .slider-buttons {
  flex: auto;
  display: none;
  justify-content: flex-end;
}
@media only screen and (min-width: 1020px) {
  .slider .slider-buttons {
    display: flex;
  }
}
.slider .slider-buttons .slider-button {
  right: 0;
  left: 0;
  height: 60px;
  width: 60px;
  min-width: 60px;
  appearance: none;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #004E34;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  color: #004E34;
}
.slider .slider-buttons .slider-button + .slider-button {
  margin-left: 15px;
}
.slider .slider-buttons .slider-button:hover {
  border-color: #40E6B0;
}
.slider .slider-slides {
  flex: 100%;
  margin-top: 34px;
}
.slider .slider-slides .slider-slide:not(.is-visible) {
  filter: opacity(0.5);
}

.input-box {
  position: relative;
  max-width: 465px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 6px;
  border-radius: 22px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  background-color: #fff;
  filter: drop-shadow(0px 0px 8px rgba(108, 108, 108, 0.44));
}
@media only screen and (min-width: 768px) {
  .input-box {
    width: 100%;
  }
}
.input-box:hover {
  border-color: #00DE95;
}
.input-box input[type=email] {
  margin-top: 10px;
  padding: 6px 20px 0 20px;
  flex: 1 1 190px;
  width: 100%;
  height: 46px;
  appearance: none;
  border: 0;
  background: none;
  transition: all 0.5s ease-in, all 0s ease-out;
}
@media only screen and (min-width: 768px) {
  .input-box input[type=email] {
    margin-top: 0;
    padding: 0 20px;
    height: 42px;
  }
}
.input-box input[type=email]:focus, .input-box input[type=email]:active, .input-box input[type=email]:not(:placeholder-shown):not(:focus) {
  outline: none;
  padding-top: 6px;
  padding-bottom: 0;
  background-color: transparent;
}
@media only screen and (min-width: 768px) {
  .input-box input[type=email]:focus, .input-box input[type=email]:active, .input-box input[type=email]:not(:placeholder-shown):not(:focus) {
    padding-top: 12px;
  }
}
.input-box input[type=email]:focus::placeholder, .input-box input[type=email]:active::placeholder, .input-box input[type=email]:not(:placeholder-shown):not(:focus)::placeholder {
  opacity: 0;
}
.input-box input[type=email]:focus + label, .input-box input[type=email]:active + label, .input-box input[type=email]:not(:placeholder-shown):not(:focus) + label {
  opacity: 1;
  top: 12px;
  font-size: 14px;
}
.input-box input[type=email]::placeholder {
  color: #739E90;
  line-height: 15px;
}
.input-box label {
  transition: font 0.5s, top 0.5s;
  position: absolute;
  left: 26px;
  top: 24px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  z-index: 1;
  opacity: 0;
  color: #739E90;
  line-height: 15px;
}
.input-box .button {
  flex: 1 1 120px;
  margin: 8px 3px;
}

ol, ul {
  padding: 0;
}
ol + ol, ol ul, ul + ol, ul ul {
  margin-top: 45px;
}
ol li + li, ul li + li {
  margin-top: 10px;
}
ol li > ol, ol li ul, ul li > ol, ul li ul {
  margin-top: 10px;
}

ol {
  counter-reset: item;
}
ol > li {
  display: block;
}
ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

ol.main-list > li > p {
  font-size: 16px;
}
ol.main-list > li + li {
  margin-top: 22px;
}
ol.main-list > li > ul li, ol.main-list > li ol li {
  font-size: 16px;
}
ol.main-list > li > ul li + li, ol.main-list > li ol li + li {
  margin-top: 16px;
}
ol.main-list > li > ul li {
  margin-left: 15px;
}

table {
  font-size: 16px;
}
table thead {
  background-color: #00A670;
  color: #fff;
}
table thead tr th {
  padding: 8px;
}
table tbody tr:nth-child(odd) td {
  background-color: #F2FDF9;
}
table tbody td {
  padding: 8px;
}
table tbody td + td {
  border-left: 1px solid #004E34;
}
@media only screen and (max-width: 768px) {
  table, table thead, table tbody, table th, table td, table tr {
    display: block;
  }
  table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table tbody tr {
    border-top: 1px solid #004E34;
    border-bottom: 1px solid #004E34;
  }
  table tbody tr + tr {
    margin-top: 10px;
  }
  table tbody td {
    min-height: 130px;
    border: none;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }
  table tbody td + td {
    border: none;
    border-top: 1px solid #739E90;
  }
  table tbody td:before {
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(35% + 5vw);
    background-color: #00A670;
    text-align: left;
    font-weight: bold;
    color: #fff;
    content: attr(data-title);
  }
}

.app-links {
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #004E34;
}
a:hover {
  text-decoration: underline;
}
a.mail-to, a.hero-link {
  font-weight: 700;
  text-decoration: underline;
}
a.mail-to:hover, a.hero-link:hover {
  text-decoration: none;
}

.articles {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.articles .article {
  flex: 1 1 100%;
}
@media only screen and (min-width: 1020px) {
  .articles .article {
    flex: 0 1 312px;
  }
}

.article {
  padding: 6px;
  border-radius: 24px;
  background: #fff;
}
.article img {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
  object-fit: cover;
}
.article h6 {
  color: #739E90;
}
.article .article-text {
  padding: 10px;
  font-size: 25px;
  white-space: normal;
}

.hero {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  background-image: url("../img/header_background_mobile.png");
  background-position: center -80px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 768px) {
  .hero {
    background-image: url("../img/header_background_desktop.png"), url("../img/header_background_desktop_2.png");
    background-position: center -80px, center -80px;
    background-repeat: no-repeat, no-repeat;
    background-size: auto;
  }
}
.hero.hero-landing .hero-text {
  flex: 0 1 600px;
}
@media only screen and (min-width: 768px) {
  .hero.hero-landing {
    min-height: 585px;
  }
}
.hero h1 {
  font-size: 56px;
  line-height: 65px;
}
@media only screen and (min-width: 1020px) {
  .hero h1 {
    font-size: 80px;
    line-height: 95px;
  }
}
.hero .hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 1020px) {
  .hero .hero-text {
    margin: 40px 0 40px 45px;
  }
}
.hero .hero-text .button {
  padding: 0 32px;
  font-size: 20px;
  line-height: 3.2rem;
}
.hero .hero-graphics {
  flex: 0 1 600px;
  position: relative;
}
.hero .hero-graphics .hero-app-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .hero .hero-graphics .hero-app-links {
    width: 150px;
  }
}
.hero .hero-graphics .hero-app-links .header {
  flex: 100%;
}
.hero .hero-graphics .hero-app-links .button {
  margin: 0;
}
.hero .hero-graphics .hero-greg-cutout {
  margin-top: 30px;
  width: 100%;
  height: auto;
}
.hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hero .hero-graphics {
    height: 555px;
  }
  .hero .hero-graphics .hero-app-links, .hero .hero-graphics .hero-greg-cutout, .hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
    position: absolute;
  }
  .hero .hero-graphics .hero-app-links {
    top: 0;
    left: 0;
    z-index: 2;
  }
  .hero .hero-graphics .hero-greg-cutout {
    margin: 0;
    top: 100px;
    left: 10px;
    width: 520px;
    z-index: 1;
  }
  .hero .hero-graphics .hero-mobile, .hero .hero-graphics .hero-ipad {
    display: initial;
  }
  .hero .hero-graphics .hero-ipad {
    top: 55px;
    left: 229px;
    width: 325px;
    z-index: 0;
  }
  .hero .hero-graphics .hero-mobile {
    top: 227px;
    left: 480px;
    width: 140px;
    z-index: 1;
  }
}

.blogs.area {
  padding: 60px 20px 20px 20px;
}
.blogs .blog-header {
  margin-bottom: 40px;
}
.blogs .articles {
  justify-content: flex-start;
}
@media only screen and (min-width: 1020px) {
  .blogs .articles img {
    height: 220px;
  }
}

.hero-blog {
  justify-content: space-between;
}
.hero-blog .article {
  flex: 1 1 100%;
}
.hero-blog .hero-text {
  flex: 1 1 400px;
}
.hero-blog .hero-text .blog-author {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.hero-blog .hero-text .blog-author img {
  margin-right: 15px;
  padding: 6px;
  border: 6px solid #00de95;
  border-radius: 50%;
}
.hero-blog .blog-image {
  margin: 20px -15px -15px -15px;
  flex: 1 1 510px;
  overflow: hidden;
}
@media only screen and (min-width: 1020px) {
  .hero-blog .blog-image {
    margin: -15px;
    border-top-left-radius: 20px;
  }
}
.hero-blog .blog-image img {
  height: 320px;
  width: 100%;
  object-fit: cover;
}

.blog-text {
  margin: 0 auto;
  padding: 15px;
}
@media only screen and (min-width: 1020px) {
  .blog-text {
    padding: 40px;
    width: 100%;
    max-width: 760px;
  }
  .blog-text p {
    font-size: 20px;
  }
}
.blog-text img {
  max-height: 300px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.blog-share {
  margin: 20px 0 60px 0;
}
.blog-share ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}
@media only screen and (min-width: 1020px) {
  .blog-share ul {
    gap: 45px;
  }
}
.blog-share ul li {
  display: block;
  margin: 0;
  padding: 0;
}
.blog-share ul li a {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  border-color: white;
  box-shadow: 0 0 0 6px #00de95;
  background-color: #00A670;
}
.blog-share ul li a img {
  height: 24px;
  width: 24px;
}
@media only screen and (min-width: 1020px) {
  .blog-share ul li a {
    height: 62px;
    width: 62px;
  }
  .blog-share ul li a img {
    height: 32px;
    width: 32px;
  }
}

body {
  font: 100% DMSans, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #004E34;
}

main, footer {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s linear;
}
main.mobile-hidden, footer.mobile-hidden {
  visibility: hidden;
  opacity: 0;
}
@media only screen and (min-width: 1020px) {
  main.mobile-hidden, footer.mobile-hidden {
    visibility: visible;
    opacity: 1;
  }
}

.container {
  margin: 0 auto;
  padding: 5px;
  max-width: 1420px;
}
@media only screen and (min-width: 1020px) {
  .container {
    padding: 10px;
  }
}

.section + .section {
  margin-top: 15px;
}

.navigation {
  width: 100%;
}

nav {
  flex: fit-content;
  bottom: 0;
  height: calc(100vh - 9rem);
  left: 0;
  margin: 0 5px 5px;
  padding: 1rem;
  position: fixed;
  top: calc(73px + 3vw);
  right: 0;
  transform: translate(calc(100% + 5px), 0);
  transition: transform ease-in 0.3s;
  z-index: 999;
  display: flex;
  flex-flow: column;
  border-radius: 0 0 20px 20px;
  background-color: #aaedd6;
  color: #004E34;
}
nav.active {
  transform: none;
}
@media only screen and (min-width: 1020px) {
  nav {
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    justify-content: center;
    position: relative;
    top: 0;
    transform: none;
    transition: none;
    background-color: transparent;
    flex-flow: row;
  }
}
nav ul {
  top: 0;
  height: auto;
  padding: 0;
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  transform: none;
}
@media only screen and (min-width: 1020px) {
  nav ul {
    flex-flow: row;
    justify-content: center;
  }
}
nav ul li {
  display: inline-block;
  line-height: 4em;
}
@media only screen and (min-width: 1020px) {
  nav ul li {
    margin: 0 15px;
  }
}
nav a {
  font-size: 32px;
  font-weight: 700;
}
@media only screen and (min-width: 1020px) {
  nav a {
    font-size: 16px;
  }
}
nav .button + .button {
  margin-left: 0;
}
@media only screen and (min-width: 1020px) {
  nav .button + .button {
    margin-left: 20px;
  }
}
nav .button.primary-button, nav .button.secondary-button {
  width: auto;
}

.hero.hero-404 .hero-text {
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.hero.hero-404 .hero-text h2, .hero.hero-404 .hero-text h3, .hero.hero-404 .hero-text h5 {
  flex: 100%;
  text-align: center;
}
.hero.hero-404 .hero-text h3 {
  color: #A6C1B8;
}
.hero.hero-404 .hero-text h2 {
  margin-top: 10px;
}
.hero.hero-404 .hero-text h5 {
  margin-top: 10px;
  margin-bottom: 40px;
  color: #739E90;
}

.featured {
  display: flex;
  justify-content: center;
  gap: 15px;
  overflow-x: hidden;
  white-space: nowrap;
}
.featured img {
  border-radius: 20px;
  height: 300px;
  width: 300px;
  object-fit: cover;
}
@media only screen and (min-width: 768px) {
  .featured img {
    height: 350px;
    width: 350px;
  }
}
@media only screen and (min-width: 1020px) {
  .featured img {
    height: 465px;
    width: 465px;
  }
}

.points.tiles {
  padding: 100px 50px;
  gap: 60px;
}

.planner {
  padding: 80px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 80px;
}
@media only screen and (min-width: 1020px) {
  .planner {
    padding: 120px 50px;
    flex-direction: row;
    gap: 15px;
    background-image: url("../img/planner/panoramic_background.png");
    background-repeat: no-repeat;
    background-position: top center;
  }
}
.planner .planner-images {
  position: relative;
  order: 2;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 1020px) {
  .planner .planner-images {
    flex: 0 1 250px;
    order: 1;
  }
}
.planner .planner-images .image {
  width: 280px;
  border-radius: 15px;
}
@media only screen and (min-width: 768px) {
  .planner .planner-images .image {
    width: 353px;
  }
}
.planner .planner-texts {
  flex: 2 1 500px;
  order: 1;
}
@media only screen and (min-width: 1020px) {
  .planner .planner-texts {
    flex: 0 1 500px;
    order: 2;
  }
}
.planner .planner-texts .button {
  margin-top: 30px;
}

.recipes.area {
  padding: 30px 20px;
}
@media only screen and (min-width: 1020px) {
  .recipes.area {
    padding: 60px 40px;
  }
}
.recipes .recipe img {
  padding: 0;
  display: block;
  height: 220px;
  width: -webkit-fill-available;
  border-radius: 20px;
  object-fit: cover;
}
@media only screen and (min-width: 768px) {
  .recipes .recipe img {
    margin: 0;
    height: 280px;
  }
}
@media only screen and (min-width: 1020px) {
  .recipes .recipe img {
    margin: 0;
    height: 320px;
  }
}
.recipes .recipe .recipe-text {
  margin: 10px auto 0;
  max-width: 320px;
}

.additional .button-container {
  margin-top: 20px;
  flex: 100%;
  display: flex;
  justify-content: center;
}

.testimonials {
  padding: 20px;
}
@media only screen and (min-width: 1020px) {
  .testimonials {
    padding: 85px 180px;
  }
}
.testimonials h4 {
  text-align: center;
}
@media only screen and (min-width: 1020px) {
  .testimonials h4 {
    text-align: left;
  }
}
.testimonials .testimonial {
  display: flex;
  flex-flow: column;
}
@media only screen and (min-width: 1020px) {
  .testimonials .testimonial {
    flex-flow: row;
  }
}
.testimonials .testimonial .testimonial-person {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (min-width: 1020px) {
  .testimonials .testimonial .testimonial-person {
    margin-right: 60px;
  }
}
.testimonials .testimonial .testimonial-person .image-border {
  margin-bottom: 10px;
  display: flex;
  height: 180px;
  width: 180px;
  padding: 6px;
  border-style: solid;
  border-width: 6px;
  border-radius: 50%;
  border-color: #00DE95;
}
.testimonials .testimonial .testimonial-person .image-border img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.testimonials .testimonial .testimonial-text {
  flex: 1;
  margin-top: 40px;
}
@media only screen and (min-width: 1020px) {
  .testimonials .testimonial .testimonial-text {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}
.testimonials .testimonial .testimonial-text h5:before {
  content: open-quote;
}
.testimonials .testimonial .testimonial-text h5:after {
  content: close-quote;
}

.signup {
  padding: 80px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  overflow: hidden;
  gap: 40px;
}
@media only screen and (min-width: 1020px) {
  .signup {
    height: 435px;
    flex-flow: row;
  }
}
.signup .signup-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-content: space-around;
  justify-content: center;
  min-width: 315px;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images {
    margin-top: -34px;
    height: 565px;
    flex-direction: column;
    justify-content: flex-start;
    column-count: 2;
  }
}
.signup .signup-images.images-left {
  margin-left: -70px;
  margin-right: -70px;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images.images-left {
    margin-left: -70px;
    margin-right: 0;
  }
}
.signup .signup-images.images-right {
  display: none;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images.images-right {
    margin-right: -70px;
    display: flex;
  }
}
.signup .signup-images img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 20px;
  object-fit: cover;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images img {
    width: 150px;
    height: 150px;
  }
}
.signup .signup-images .spacer-column {
  height: 0;
  display: none;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images .spacer-column {
    display: block;
    height: 65px;
  }
}
.signup .signup-images .spacer-row {
  width: 65px;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-images .spacer-row {
    display: none;
    width: 0;
  }
}
.signup h3, .signup p {
  text-align: center;
}
.signup .signup-content {
  margin-bottom: 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
@media only screen and (min-width: 1020px) {
  .signup .signup-content {
    margin-bottom: 0;
    flex: 0 1 654px;
  }
}

.download {
  padding: 40px;
  display: flex;
  flex-flow: column;
  gap: 15px;
  overflow: hidden;
}
.download.area {
  background-color: #004E34;
  color: #fff;
}
@media only screen and (min-width: 1020px) {
  .download {
    padding: 100px;
    flex-flow: row;
  }
}
.download .download-content {
  flex: 1;
}
.download .download-content ul {
  list-style-type: none;
  margin: 30px 0 0 0;
  padding: 0;
}
.download .download-content ul li {
  position: relative;
  padding-left: 50px;
  font-size: 20px;
  font-weight: 700;
}
.download .download-content ul li + li {
  margin-top: 20px;
}
.download .download-content ul li:before {
  position: absolute;
  left: 0;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #40E6B0;
  content: url("../img/icons/tick_mark.svg");
}
.download .download-pricing {
  margin-top: 75px;
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
}
@media only screen and (min-width: 1020px) {
  .download .download-pricing {
    margin-top: 0;
  }
}
.download .download-pricing .boxes {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;
}
.download .download-pricing .boxes .box {
  flex: 220px;
  margin-bottom: 20px;
  z-index: 1;
}
.download .download-pricing .boxes .price {
  font-size: 40px;
  font-weight: 500;
  color: #004E34;
}
.download .download-pricing .boxes .per {
  font-size: 14px;
  font-weight: 500;
  color: #739E90;
  text-align: center;
}
.download .download-pricing .boxes .other {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #407A67;
  text-align: center;
}
.download .download-pricing .boxes .button {
  margin-top: 40px;
}
.download .download-pricing .app-links {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.download .download-pricing .app-links h5 {
  flex: 100%;
  text-align: center;
}

.press {
  padding: 15px;
}
@media only screen and (min-width: 1020px) {
  .press {
    padding: 70px;
  }
}
.press .press-content {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 45px;
  align-items: center;
}
.press .press-content .press-image {
  margin: 0 auto;
}
.press .press-content .press-image img {
  width: 100%;
  border-radius: 20px;
}
@media only screen and (min-width: 1020px) {
  .press .press-content .press-image img {
    width: auto;
  }
}
.press .press-content .press-text {
  flex: 1 1 520px;
  font-size: 32px;
  color: #739E90;
}
.press .press-content .press-newspapers {
  flex: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 45px;
  align-items: center;
  justify-content: space-evenly;
}
.press .press-content .press-newspapers img {
  max-height: 72px;
  width: 100%;
  object-fit: contain;
}
@media only screen and (min-width: 1020px) {
  .press .press-content .press-newspapers img {
    height: 100px;
    width: auto;
  }
}

/*# sourceMappingURL=site.css.map */
